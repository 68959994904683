










import BazaarApi from "@/api/bazaar.api";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
    components:{
    }
})
export default class ArticePage extends Vue {
    pageData:any = {}
    timer = ''
  async created() {
    this.pageData = await BazaarApi.getArticePage(this.$route.params.id)
    this.timer = `${new Date(this.pageData.create_time*1000).getFullYear()}年${new Date(this.pageData.create_time*1000).getMonth()+1}月${new Date(this.pageData.create_time*1000).getDate()}日`
    console.log(this.timer)
}
 
}
